//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "home",
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      scrolledRingClass: 'ring--0',
      showRingText: false,
      minFrame: 0,
      maxFrame: 50,
      friction: 5,
    }
  },
  methods: {
    getScrollTop() {
      return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    },
    ringOpen(event) {
      
      if (window.innerWidth < 1080) {
        this.showRingText = true;
      }
      
      let offsety = this.$refs['ring'].getBoundingClientRect().y;
      let y = 0;
      if (window.innerWidth <= 1350) {
        y = -40;
      }
      if (window.innerWidth <= 1080) {
        y = -160;
      }
      let offsetRing = -1;
      if (offsety + y < 0) {
        offsetRing = Math.abs(offsety + y) / 6;
      }
      if (offsetRing >= this.minFrame && offsetRing <= this.maxFrame) {
        this.scrolledRingClass = 'ring--' + offsetRing.toFixed(0);
        if (window.innerWidth >= 1080) {
          this.showRingText = offsetRing > 20;
        }
      }
      else if (offsetRing < this.minFrame) {
        this.scrolledRingClass = 'ring--' + this.minFrame;
      }
      else if (offsetRing > this.maxFrame) {
        this.scrolledRingClass = 'ring--' + this.maxFrame;
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.ringOpen);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.ringOpen)
  },
}
